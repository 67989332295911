import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ScheduleForm } from "@/model/api/Schedule";
import { VesselInfo } from "@/model/PortOperation";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import data from "./data";

interface VesselState {
  shipData: { visible: false; data: VesselInfo[] };
}

interface Coordinates {
  id: string;
  name: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

interface ScheduleFormError {
  anchorage_id: string;
  vessel_imo: string;
}
interface ScheduleFormProps {
  scheduleForm: ScheduleForm;
  setScheduleForm: (schedule: ScheduleForm) => void;
  handleScheduleSelectTakeOffLandingPoint: (
    coordinate: number[],
    isTakeOff: boolean
  ) => void;
  scheduleFormError: ScheduleFormError;
  isRouteOperation?: boolean;
}

export default function CreateScheduleForm({
  scheduleForm,
  setScheduleForm,
  handleScheduleSelectTakeOffLandingPoint,
  scheduleFormError,
  isRouteOperation,
}: ScheduleFormProps) {
  /* Form Data */
  const [form, setForm] = useState<ScheduleForm>({
    anchorage_details: {
      anchorage_id: "",
      anchorage_name: "",
    },
    vessel_details: {
      vessel_imo: "",
      vessel_mmsi: "",
      vessel_callsign: "",
      vessel_name: "",
    },
    unisphere_weather_simulation: false,
  });
  /* Takeoff Point */
  const [isTakeOffDropdownOpen, setIsTakeOffDropdownOpen] = useState(false);
  const [selectedTakeOffOption, setSelectedTakeOffOption] =
    useState<Coordinates | null>();

  /* Anchorage */
  const [isAnchorageDropdownOpen, setIsAnchorageDropdownOpen] = useState(false);
  const [selectedAnchorageOption, setSelectedAnchorageOption] =
    useState<Coordinates | null>();

  /* Vessel */
  const [vessels, setVessels] = useState<VesselInfo[] | null>();
  const [isVesselDropdownOpen, setIsVesselDropdownOpen] = useState(false);
  const [selectedVesselOption, setSelectedVesselOption] =
    useState<VesselInfo | null>();

  const [filterValue, setFilterValue] = useState<string | null>();

  const { takeoffPoint, anchorageDetails } = data;

  const anchorageFilterRef = useRef<HTMLInputElement | null>(null);
  const vesselFilterRef = useRef<HTMLInputElement | null>(null);
  const vesselDropdownRef = useRef<HTMLInputElement | null>(null);

  const shipData = useSelector((state: VesselState) => state.shipData.data);

  useEffect(() => {
    setVessels(shipData);
  }, [shipData]);

  const handleSelectedTakeOffOption = (option: Coordinates) => {
    setSelectedTakeOffOption(option);
    setIsTakeOffDropdownOpen(false);
    const coordinates = [option.coordinates.lng, option.coordinates.lat];
    handleScheduleSelectTakeOffLandingPoint(coordinates, true);
  };

  const handleSelectedAnchorageOption = (option: Coordinates) => {
    setSelectedAnchorageOption(option);
    const coordinates = [option.coordinates.lng, option.coordinates.lat];
    handleScheduleSelectTakeOffLandingPoint(coordinates, false);
    setIsAnchorageDropdownOpen(false);
    setFilterValue(null);
    const anchorage_details = {
      anchorage_id: option.id,
      anchorage_name: option.name,
    };
    setScheduleForm({
      ...scheduleForm,
      anchorage_details,
    });
  };

  const handleSelectedVesselOption = (option: VesselInfo) => {
    setIsVesselDropdownOpen(false);
    setFilterValue(null);
    const vessel_details = {
      vessel_imo: option.imo,
      vessel_mmsi: option.mmsi,
      vessel_callsign: option.callSign,
      vessel_name: option.vesselName,
    };
    setScheduleForm({
      ...scheduleForm,
      vessel_details,
    });
  };

  const handleFilterDropdown = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2) {
      setFilterValue(e.target.value);
    } else {
      setFilterValue(null);
    }
  };

  const handleVesselDetailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "vessel_imo") {
      setFilterValue(e.target.value);
      setScheduleForm({
        ...scheduleForm,
        vessel_details: {
          ...scheduleForm.vessel_details,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setScheduleForm({
        ...scheduleForm,
        vessel_details: {
          ...scheduleForm.vessel_details,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleCheckboxChange = () => {
    const weatherSimulation = scheduleForm.unisphere_weather_simulation;
    setScheduleForm({
      ...scheduleForm,
      unisphere_weather_simulation: !weatherSimulation,
    });
  };

  useEffect(() => {
    setFilterValue(null);
    isAnchorageDropdownOpen &&
      anchorageFilterRef.current &&
      anchorageFilterRef.current.focus();
    isVesselDropdownOpen &&
      vesselFilterRef.current &&
      vesselFilterRef.current.focus();
  }, [isAnchorageDropdownOpen, isVesselDropdownOpen]);

  return (
    <div className="mb-4">
      {/* Anchorage Dropdown Option Picker */}
      <h5 className="font-medium ml-6 mt-4 mb-2">Anchorage</h5>
      <div className="relative px-4">
        <button
          className="input-select w-full"
          type="button"
          onClick={() => setIsAnchorageDropdownOpen(!isAnchorageDropdownOpen)}
        >
          {isAnchorageDropdownOpen ? (
            <div className="w-full flex items-center justify-between">
              {/* Input Field */}
              <span className="grow">
                <input
                  type="text"
                  className="input-text w-full"
                  onChange={handleFilterDropdown}
                  ref={anchorageFilterRef}
                />
              </span>
              {/* Arrow Up Icon */}
              <IoIosArrowUp size={18} className="ml-2" />
            </div>
          ) : (
            <div className="w-full flex items-center justify-between">
              {/* Selected Option */}
              <span className="grow">
                {scheduleForm?.anchorage_details.anchorage_name || ""}
              </span>
              {/* Arrow Down Icon */}
              <IoIosArrowDown size={18} className="ml-2" />
            </div>
          )}
        </button>
        {scheduleFormError.anchorage_id && (
          <p className="text-red-500 text-sm mt-1">
            {scheduleFormError.anchorage_id}
          </p>
        )}

        {isAnchorageDropdownOpen && (
          <div className="absolute top-14 w-[95%] z-[150]">
            <ul className="dropdown-ul">
              {anchorageDetails
                .filter((singleAnchoragePoint) =>
                  filterValue
                    ? singleAnchoragePoint.name
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    : true
                )
                .map((singleAnchoragePoint: Coordinates) => (
                  <li
                    key={singleAnchoragePoint.id}
                    className="dropdown-li"
                    onClick={() =>
                      handleSelectedAnchorageOption(singleAnchoragePoint)
                    }
                  >
                    {singleAnchoragePoint.name}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>

      {/* Takeoff Dropdown Option Picker */}
      {isRouteOperation && (
        <div
          className="flex items-center px-2 cursor-pointer"
          onClick={() => setIsTakeOffDropdownOpen(!isTakeOffDropdownOpen)}
        >
          <span className="grow" />
          <p className="px-4 text-blue-500 text-sm font-medium mt-1">
            Load Preset Takeoff Point
          </p>
        </div>
      )}
      <div className="relative">
        {isTakeOffDropdownOpen && (
          <div className="absolute top-1 px-4 w-[95%] z-[150]">
            <ul className="dropdown-ul">
              {takeoffPoint.map((singleTakeoffPoint: Coordinates) => (
                <li
                  key={singleTakeoffPoint.id}
                  className="dropdown-li"
                  onClick={() =>
                    handleSelectedTakeOffOption(singleTakeoffPoint)
                  }
                >
                  {singleTakeoffPoint.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Vessel Data */}
      <div className="flex mb-2 w-full px-4">
        <div className="relative w-full">
          <div className="flex">
            <div className="w-1/3 mr-2">
              <label htmlFor="elevation" className="label">
                Vessel IMO
              </label>
              <div className="flex items-center">
                <div className="relative">
                  <input
                    type="text"
                    className="input-text"
                    name="vessel_imo"
                    value={scheduleForm.vessel_details.vessel_imo || ""}
                    onChange={handleVesselDetailChange}
                    onFocus={() => setIsVesselDropdownOpen(true)}
                  />
                  <span className="absolute right-1 top-1/2 transform -translate-y-1/2 cursor-pointer">
                    {isVesselDropdownOpen ? (
                      <IoIosArrowUp
                        size={18}
                        onClick={() => setIsVesselDropdownOpen(false)}
                      />
                    ) : (
                      <IoIosArrowDown
                        size={18}
                        onClick={() => setIsVesselDropdownOpen(true)}
                      />
                    )}
                  </span>
                </div>
              </div>

              {scheduleFormError.vessel_imo && (
                <p className="text-red-500 text-sm mt-1">
                  {scheduleFormError.vessel_imo}
                </p>
              )}
            </div>
            <div className="w-full">
              <label htmlFor="altitude" className="label">
                Vessel Name
              </label>
              <input
                type="text"
                name="vessel_name"
                className="input-text"
                value={scheduleForm.vessel_details.vessel_name || ""}
                onChange={handleVesselDetailChange}
              />
            </div>
          </div>
          {isVesselDropdownOpen && (
            <div
              ref={vesselDropdownRef}
              className="absolute top-30 w-[95%] z-[150]"
            >
              <ul className="dropdown-ul">
                {vessels &&
                  vessels
                    .filter((singleVessel) =>
                      filterValue
                        ? singleVessel.callSign
                            .toLowerCase()
                            .includes(filterValue.toLowerCase()) ||
                          singleVessel.imo
                            .toLowerCase()
                            .includes(filterValue.toLowerCase()) ||
                          singleVessel.vesselName
                            .toLowerCase()
                            .includes(filterValue.toLowerCase())
                        : true
                    )
                    .map((singleVessel: VesselInfo) => (
                      <li
                        key={`${singleVessel.imo}${singleVessel.callSign}${singleVessel.vesselName}`}
                        className="dropdown-li"
                        onClick={() => handleSelectedVesselOption(singleVessel)}
                      >
                        {`${singleVessel.vesselName} - ${singleVessel.callSign}: ${singleVessel.imo}`}
                      </li>
                    ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="relative px-4 mb-4">
        <div className="flex mb-2">
          <div className="w-full mr-2">
            <label htmlFor="elevation" className="label">
              MMSI
            </label>
            <input
              type="text"
              className="input-text"
              name="vessel_mmsi"
              value={scheduleForm.vessel_details.vessel_mmsi || ""}
              onChange={handleVesselDetailChange}
            />
            {/* {formError.elevation && (
            <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
          )} */}
          </div>
          <div className="w-full">
            <label htmlFor="altitude" className="label">
              Callsign
            </label>
            <input
              type="text"
              name="vessel_callsign"
              className="input-text"
              value={scheduleForm.vessel_details.vessel_callsign || ""}
              onChange={handleVesselDetailChange}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center mt-4 mb-4 px-4">
        <input
          type="checkbox"
          className="input-checkbox"
          name="unisphere_weather_simulation"
          checked={scheduleForm.unisphere_weather_simulation || false}
          onChange={() => handleCheckboxChange()}
        />
        <label className="ml-2 text-sm">Weather Simulation</label>
      </div>
    </div>
  );
}
