/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { Amplify, Auth } from "aws-amplify";
import DashboardHeader from "@/components/v1/Header";
import MapView from "@/components/v1/Map";
import DashboardSideBar from "@/components/v1/SideBar";
import MapToolbar from "@/components/v1/Toolbar";
import { getUA } from "react-device-detect";
import { useCallback, useEffect, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import useWebsocket from "@/hooks/useWebsocket";
import { channels } from "@/config/channels";
import UserAccess from "@/model/UserAccess";
import { messageTypes } from "@/config/messageTypes";
import { FLIGHT_STATES } from "@/config/flightStatusColor";
import { IoIosClose, IoIosAlert } from "react-icons/io";
import AssetManagementSidebar from "@/components/v1/MainMenu/AssetManagement";
import DronePortsOperationSidebar from "@/components/v1/MainMenu/DronePortsOperation";
import ReportsSidebar from "@/components/v1/MainMenu/Reports";
import ImportOperationSidebar from "@/components/v1/MainMenu/ImportOperation";
import FlightAuthorizationSidebar from "@/components/v1/MainMenu/FlightAuthorization";
import WeatherAnalysisSidebar from "@/components/v1/MainMenu/WeatherAnalysis";
import Waypoint from "@/model/Waypoint";
import circle from "@turf/circle";
import { Schedule } from "@/model/api/Schedule";
import { CreateOperationType } from "@/enum/CreateOperationType";
import toast, { Toaster } from "react-hot-toast";
import Button from "@/components/v1/Common/Button";
import LatLng from "@/model/LatLng";
import { VesselInfo } from "@/model/PortOperation";
import MapState from "@/model/MapState";
import { FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";
import OperationPanel from "@/components/v1/MainMenu/OperationPanel";
import FlightData from "@/model/api/FlightData";
import { OperationJson } from "@/model/api/Operation";
import Tracker from "@/model/api/Tracker";
import Pilot from "@/model/api/Pilot";
import Platform from "@/model/api/Platform";
import Constraint from "@/model/api/Constraint";
import useCognitoAuth from "@/hooks/useCognitoAuth";
import ConstraintManagementSidebar from "@/components/v1/MainMenu/ConstraintManagement";
import RerouteRescheduleDialog from "@/components/v1/Dialog/RerouteRescheduleDialog";
import FlightPlan from "@/model/FlightPlan";
import { IoCloseCircle } from "react-icons/io5";
import useColorMode from "@/hooks/useColorMode";
import FlightLogs from "@/components/v1/MainMenu/FlightLogs";
import TrackerManagement from "@/components/v1/MainMenu/TrackerManagement";
import OperatorManagement from "@/components/v1/MainMenu/OperatorManagement";
import FlightAuthorizationAuthority from "@/components/v1/MainMenu/FlightAuthorizationAuthority";
import UserManagement from "@/components/v1/MainMenu/UserManagement";
import djiPilot2Integration from "@/components/v1/MainMenu/DJIPilot2Integration";
// import DJIMissionAPI from "../../Protected/Drone_Integration/DJIMissionAPI";
import {
  getShipData,
  setSnackbarMessage,
  getADSBDataPaid,
  getADSBDataFree,
  setEditConstraint,
  getairMapSpaceData,
  setStateConformance,
  setOperationFormRequest,
} from "../../../store/actions";
import { useApi } from "../../../api/useApi";
import { SelectedCircleProperties } from "./DashboardModel";

interface UserAccessState {
  userAccess: UserAccess;
}

export default function DashboardPage() {
  const { channel, reconnectWs } = useWebsocket({
    channel: channels.NOTIFICATION_CHANNEL,
  });
  const { isAuthority, isRemoteId } = useCognitoAuth();
  const dispatch = useDispatch();

  /* App Theme */
  const [colorMode] = useColorMode();
  const [currentTheme, setCurrentTheme] = useState(
    colorMode === "light" ? "light" : "dark"
  );

  /* Notifications */
  /* const {
    notifications,
    haveNewNotifications,
    setNotifications,
    setHaveNewNotifications,
    olderNotifications,
    handleGetOlderNotifications,
  } = useNotifications();
  const notificationsRef = useRef(notifications); */

  /* Map */
  const { mapboxController } = useSelector((state: MapState) => state.maps);
  const [constraints, setConstraints] = useState<[]>([]);

  /* Loading State */
  const [flightDataIsLoading, setFlightDataIsLoading] = useState(true);

  /* Operations & Assets Information */
  const [operationSummary, SetOperationsSummary] = useState(0);
  const [groundSummary, SetGroundSummary] = useState(0);
  const [airborneSummary, SetAirborneSummary] = useState(0);
  const [selectedFlightData, setSelectedFlightData] = useState<OperationJson[]>(
    []
  );
  const platformList = useRef<{ [key: string]: Platform } | null>(null);
  const trackerList = useRef<{ [key: string]: Tracker } | null>(null);
  const pilotList = useRef<{ [key: string]: Pilot } | null>(null);
  const [selectedPlatformData, setSelectedPlatformData] =
    useState<{ data: Platform }[][]>();
  const [selectedTrackerData, setSelectedTrackerData] =
    useState<{ data: Tracker }[][]>();

  /* Map Layer Visibility Toggle */
  const [showShipLayer, setShowShipLayer] = useState(false);
  const [showAdsbLayer, setShowAdsbLayer] = useState(false);
  const [showConstraintsLayer, setShowConstraintsLayer] = useState(true);
  const [showStaticConstraintsLayer, setShowStaticConstraintsLayer] =
    useState(true);
  const [showBuildingLayer, setShowBuildingLayer] = useState(false);
  const [showOperationVolumeLayer, setShowOperationVolumeLayer] =
    useState(true);
  const [showDroneIconLayer, setShowDroneIconLayer] = useState(true);
  const [showRainMapLayer, setShowRainMapLayer] = useState(false);
  const [showAirMapSpace, setShowAirMapSpaceLayer] = useState(true);
  const [editConstraintStatusGraphic, setEditConstraintStatusGraphic] =
    useState(false);
  const [showGnssLayer, setShowGnssLayer] = useState(false);
  const [showAnchorageLayer, setShowAnchorageLayer] = useState(true);
  const [showDronePortLayer, setShowDronePortLayer] = useState(false);
  const [showSgBoundaryLayer, setShowSgBoundaryLayer] = useState(false);
  const [showSgPopulationDensity, setShowSgPopulationDensity] = useState(false);
  const [showMapElevation, setShowMapElevation] = useState(false);
  const [showCollisionLayer, setShowCollisionLayer] = useState(true);

  /* Panel Visibility State */
  const [showOperationPanel, setShowOperationPanel] = useState(true);

  /* Sidebar Toggle State */
  const [hideSideBar, setHideSideBar] = useState(true);

  /* Operation Planning */
  const [emergencyLanding, setEmergencyLanding] = useState([0, 0]);
  const [selectedWaypoints, setSelectedWaypoints] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [updatedSelectedWaypoints, setUpdatedSelectedWaypoints] = useState([]);
  const [selectedAreaWaypoints, setSelectedAreaWaypoints] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [updatedSelectedAreaWaypoints, setUpdatedSelectedAreaWaypoints] =
    useState([]);
  const [selectedCircleWaypoints, setSelectedCircleWaypoints] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [updatedSelectedCircleWaypoints, setUpdatedSelectedCircleWaypoints] =
    useState([[0.0, 0.0]]);
  const [selectedCircleProperties, setSelectedCircleProperties] =
    useState<SelectedCircleProperties>({
      isCircle: true,
      center: [0.0, 0.0],
      radiusInKm: 0,
    });
  const [updateSelectedCircleProperties, setUpdatedSelectedCircleProperties] =
    useState();

  /* Sidebar */
  const [selectedMenu, setSelectedMenu] = useState("");
  const [sideBarWidth, setSideBarWidth] = useState("w-[0px]");

  /* Confirmation Modal */
  const [dialogDetail, setDialogDetail] = useState({
    title: "Are You Sure ?",
    message: "Lorem Ipsum",
  });
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState<
    (result: boolean) => void
  >(() => {});

  /* DJI Integration */
  const [djiUserUuid, setDjiUserUuid] = useState<string>("");
  const [djiUsername, setDjiUsername] = useState<string>("");

  /* General Modal */
  const [isGeneralModalOpen, setIsGeneralModalOpen] = useState(false);
  const [generalModalDetail, setGeneralModalDetail] = useState({
    title: "General Modal",
    content: <p>Lorem Ipsum</p>,
    showTitle: true,
    disableDismiss: false,
  });

  const api = useApi();
  const userAccess = useSelector((state: UserAccessState) => state.userAccess);
  const isAirspaceManager = userAccess.role === "airspace_manager";

  useEffect(() => {
    if (isAuthority) {
      Amplify.configure({
        Auth: {
          region: process.env.REACT_APP_AWS_REGION,
          userPoolId: process.env.REACT_APP_AUTHORITY_COGNITO_POOL_ID,
          userPoolWebClientId:
            process.env.REACT_APP_AUTHORITY_COGNITO_POOL_WEB_CLIENT_ID,
        },
      });
    } else {
      Amplify.configure({
        Auth: {
          region: process.env.REACT_APP_AWS_REGION,
          userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
          userPoolWebClientId: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID,
        },
      });
    }
  }, []);

  const handleSideBarSelection = (menu: string, isOpening: boolean) => {
    if (mapboxController) mapboxController.trash();
    setSelectedMenu(menu);
    if (menu === "Drone Ports Operations") {
      setSideBarWidth("w-[560px]");
    } else if (selectedMenu !== menu) {
      setSideBarWidth("w-[432px]");
    }
  };

  /* Handler Functions */
  const handleOnOperationForm = (isOpen: boolean) => {
    if (isOpen) {
      setSideBarWidth("w-[560px]");
    } else {
      setSideBarWidth("w-[432px]");
    }
  };

  const handleOnConfirmation = (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => {
    setDialogDetail({ title, message });
    setIsConfirmationModalOpen(true);
    setConfirmationCallback(() => callback);
  };

  const handleConfirmationModalClose = (result: boolean) => {
    setIsConfirmationModalOpen(false);
    if (confirmationCallback) {
      confirmationCallback(result);
    }
  };

  const handleSetSelectedWaypoints = (waypoints: number[][]) => {
    setSelectedWaypoints(waypoints);
    setHideSideBar(false);
  };

  const handleSetSelectedAreaWaypoints = (waypoints: number[][]) => {
    setSelectedAreaWaypoints(waypoints);
    setHideSideBar(false);
  };

  const handleSetSelectedCircleWaypoints = (waypoints: number[][]) => {
    setSelectedCircleWaypoints(waypoints);
    setHideSideBar(false);
  };

  const handleSetSelectedCircleProperties = (
    properties: SelectedCircleProperties
  ) => {
    setSelectedCircleProperties(properties);
  };

  const handleToggleView = (view: string, checked: boolean) => {
    switch (view) {
      case "Operation Panel":
        setShowOperationPanel(checked);
        break;
      default:
        break;
    }
  };

  const handleToggleOverlay = (overlay: string, checked: boolean) => {
    switch (overlay) {
      case "AIS":
        setShowShipLayer(checked);
        break;
      case "ADS-B":
        setShowAdsbLayer(checked);
        break;
      case "WX-RADAR":
        setShowRainMapLayer(checked);
        break;
      case "STATIC CONSTRAINTS":
        setShowStaticConstraintsLayer(checked);
        break;
      case "DYNAMIC CONSTRAINTS":
        setShowConstraintsLayer(checked);
        break;
      case "BUILDING":
        setShowBuildingLayer(checked);
        break;
      case "OPS VOLUME":
        setShowOperationVolumeLayer(checked);
        break;
      case "DRONE ICON":
        setShowDroneIconLayer(checked);
        break;
      case "ANCHORAGE":
        setShowAnchorageLayer(checked);
        break;
      case "DRONE PORT":
        setShowDronePortLayer(checked);
        break;
      case "SG BOUNDARY":
        setShowSgBoundaryLayer(checked);
        break;
      case "SG POPULATION DENSITY":
        setShowSgPopulationDensity(checked);
        break;
      case "MAP ELEVATION":
        setShowMapElevation(checked);
        break;
      case "COLLISION":
        setShowCollisionLayer(checked);
        break;
      default:
        break;
    }
  };

  const handleGeneralModal = (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => {
    setGeneralModalDetail({
      title,
      content,
      showTitle: showTitle ?? true,
      disableDismiss: disableDismiss ?? false,
    });
    setIsGeneralModalOpen(true);
  };

  const handleRepeatOperation = async (operationId: string) => {
    const response = await api.getOperationData({
      operationID: operationId,
    });
    dispatch(
      setOperationFormRequest({
        id: operationId,
        type: "duplicate",
        request: response.data.request,
      })
    );
    handleSideBarSelection("Flight Authorization", false);
  };

  const handleEditOperation = async (operationId: string) => {
    const response = await api.getOperationData({
      operationID: operationId,
    });
    dispatch(
      setOperationFormRequest({
        id: operationId,
        type: "edit",
        request: response.data.request,
      })
    );
    handleSideBarSelection("Flight Authorization", false);
  };
  /* Toast */
  const showToast = (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => {
    if (isSuccess) {
      toast.success(message, {
        style: {
          background: "#beffbd",
          color: "#296e26",
        },
        icon: "✅",
        duration: 5000,
      });
    } else if (isError) {
      toast.error(message, {
        style: {
          background: "#ff9999",
          color: "#630000",
        },
        icon: "❌",
        duration: 5000,
      });
    } else {
      toast(message, {
        style: {
          background: "#5e5e5e",
          color: "#EEE",
        },
        duration: 5000,
      });
    }
  };

  /* API Fetching */
  const getUserOperations = async () => {
    try {
      // to reduce api calls, getTrackerandPlatformData will get list of all details first
      // getPlatformData and getTrackerData will then use this list to identify tracker and platforms
      // getTrackerAndPlatformData to be called before operation selection to prevent unknown tracker
      // when there is new operation created
      await getTrackerAndPlatformData();
      const res = await api?.getAllOperationSelection();
      if (res?.status === 204 || res?.data?.length === 0) {
        return;
      }
      const flightData = res.data.map((r: FlightData) => {
        return r.operation_json;
      });

      getPlatformData(flightData);
      getTrackerData(flightData);
      // TODO::uncomment getPilotData(flightData);
    } catch (err) {
      console.log(err);
    }
  };

  const getSelectedOperation = useCallback(async () => {
    if (!api) return;
    try {
      const res = await api?.getAllOperationSelection();
      // if (res.data && res.data === 0) {
      //   SetOperationsSummary(0);
      //   SetGroundSummary(0);
      //   SetAirborneSummary(0);
      //   setSelectedFlightData([]);
      // }
      /**
       * @todo Need to improve on the error handling loading of operations.
       * @author xueyang
       */
      if (!res.data || !Array.isArray(res.data)) {
        console.log(
          "Data from getAllOperationSelection() response might be empty.",
          res
        );
        return;
      }
      const dataGroundSummary = res.data.filter(
        (obj: FlightData) =>
          obj.operation_state !== null &&
          obj.operation_state.includes("Accepted")
      ).length;
      const dataAirborneSummary = res.data.filter(
        (obj: FlightData) =>
          obj.operation_state !== null &&
          obj.operation_state.includes("Activated")
      ).length;
      const dataContingent = res.data.filter(
        (obj: FlightData) =>
          obj.operation_state !== null &&
          obj.operation_state.includes("Contingent")
      ).length;
      const dataNonConforming = res.data.filter(
        (obj: FlightData) =>
          obj.operation_state !== null &&
          obj.operation_state.includes("Nonconforming")
      ).length;
      SetOperationsSummary(
        dataGroundSummary +
          dataAirborneSummary +
          dataNonConforming +
          dataContingent
      );
      SetGroundSummary(dataGroundSummary);
      SetAirborneSummary(
        dataAirborneSummary + dataNonConforming + dataContingent
      );

      const flightData = res.data.map((r: FlightData) => {
        return r.operation_json;
      });
      // selectedFlightData.current = flightData;
      setSelectedFlightData(flightData);
    } catch (error) {
      console.log(error);
    } finally {
      setFlightDataIsLoading(false);
    }
  }, [api]);

  const getPlatformData = async (flightData: OperationJson[]) => {
    try {
      const platformIDs = await flightData.map((flight) => flight.request);
      const platformData = await Promise.all(
        platformIDs.map((platformID, index) => {
          const dataPlatform = async () => {
            const dataP = await Promise.all(
              platformID.platform_uuid.map((id) => {
                // return api?.getPlatform(id);
                return getPlatformFromUuid(id);
              })
            );
            return dataP;
          };
          return dataPlatform();
          // return api?.getPlatform(platformID[0]);
        })
      );
      setSelectedPlatformData(
        platformData as { data: Platform }[][] | undefined
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getTrackerData = async (flightData: OperationJson[]) => {
    try {
      const platformIDs = await flightData.map((flight) => flight.request);

      const trackerData = await Promise.all(
        platformIDs.map((platformID, index) => {
          // if (!platformID) return undefined;
          const dataTracker = async () => {
            const dataT = await Promise.all(
              platformID.tracker_uuid.map((tracker_uuid) => {
                // return api?.getTrackerScan(tracker_uuid);
                return getTrackerFromUuid(tracker_uuid);
              })
            );
            return dataT;
          };
          return dataTracker();
        })
      );
      setSelectedTrackerData(trackerData as { data: Tracker }[][] | undefined);
    } catch (err) {
      console.error(err);
    }
  };

  const getTrackerAndPlatformData = async () => {
    if (!api) return;
    // previously called getplatforms and gettracker for each operation
    // consolidate into useRef Object
    // const [platformListResponse, trackerListResponse, pilotListResponse] =
    //   await Promise.all([
    //     api.getPlatforms(),
    //     api.getTracker(),
    //     api.getPilots(),
    //   ]);

    const responseArray = [];
    if (userAccess.privileges.includes("assets.platform.read")) {
      responseArray.push(api.getPlatforms());
    } else {
      responseArray.push({ data: [] });
    }
    if (userAccess.privileges.includes("assets.tracker.read")) {
      responseArray.push(api.getTracker());
    } else {
      responseArray.push({ data: [] });
    }
    if (userAccess.privileges.includes("assets.pilot.read")) {
      responseArray.push(api.getPilots());
    } else {
      responseArray.push({ data: [] });
    }

    const [platformListResponse, trackerListResponse, pilotListResponse] =
      await Promise.all(responseArray);

    const formattedPlatformList: { [key: string]: Platform } = {};
    const formattedTrackerList: { [key: string]: Tracker } = {};
    const formattedPilotList: { [key: string]: Pilot } = {};

    const user = await Auth.currentAuthenticatedUser();

    platformListResponse.data.forEach((singlePlatform: Platform) => {
      formattedPlatformList[singlePlatform.platform_uuid] = singlePlatform;
    });
    trackerListResponse.data.forEach((singleTracker: Tracker) => {
      formattedTrackerList[singleTracker.tracker_uuid] = singleTracker;
    });
    pilotListResponse.data.forEach((singePilot: Pilot) => {
      formattedPilotList[singePilot.pilot_uuid] = singePilot;
      if (user.username === singePilot.pilot_username)
        setDjiUserUuid(singePilot.pilot_uuid);
    });
    platformList.current = formattedPlatformList;
    trackerList.current = formattedTrackerList;
    pilotList.current = formattedPilotList;
  };

  /* Others */
  const getPlatformFromUuid = (uuid: string) => {
    if (!platformList.current) return null;
    if (!uuid || !(uuid in platformList.current)) return null;
    return { data: platformList.current[uuid] };
  };

  const getTrackerFromUuid = (uuid: string) => {
    if (!trackerList.current) return null;
    if (!uuid || !(uuid in trackerList.current)) return null;
    return { data: trackerList.current[uuid] };
  };

  /* Sidebar Helper */
  const goToLocation = (point: LatLng) => {
    mapboxController.flyTo(point);
  };

  const handleTemporary = (from: string, data?: any) => {
    console.log(from, data);
  };

  const handleSetEmergencyLanding = (waypoints: [number, number]) => {
    setEmergencyLanding(waypoints);
  };

  useEffect(() => {
    dispatch(getShipData());
    dispatch(getADSBDataFree());
    // dispatch(getADSBDataPaid());

    const getShipDataInterval = setInterval(() => {
      dispatch(getShipData());
    }, 1000 * 60); // Updates every 1 min
    const getADSBDataInterval = setInterval(() => {
      dispatch(getADSBDataFree());
      // dispatch(getADSBDataPaid());
    }, 1000 * 60); // Updates every 1 min. Need to increase freq in future.
    return () => {
      clearInterval(getShipDataInterval);
      clearInterval(getADSBDataInterval);
    };
  }, [dispatch]);

  useEffect(() => {
    const loadConstraints = async () => {
      try {
        const res = await api?.getConstraints();
        setConstraints(res.data);
        // if (res?.data) {
        //   console.log("contstrainsdata", res.data);
        // }
      } catch (err) {
        console.log(err);
        // if (
        //   (err as any)?.response?.data?.message ===
        //   "No active constraints can be retrieved."
        // )
        //   return;
        // dispatch(
        //   setSnackbarMessage({
        //     message: (err as any).response.data.message,
        //     severity: "error",
        //   })
        // );

        setConstraints([]);
      }
    };

    const interval = setInterval(loadConstraints, 1000 * 6);
    return () => {
      clearInterval(interval);
    };
  }, [api]);

  useEffect(() => {
    if (userAccess.role === "administrator") return;
    if (
      !userAccess.privileges.some((singlePrivilege) => {
        return singlePrivilege === "operations.read";
      })
    )
      return;
    const pollUserOperations = setInterval(async () => {
      await getSelectedOperation();
      // getBuilding();
    }, 1000 * 3);
    return () => {
      clearInterval(pollUserOperations);
    };
  }, [api, getSelectedOperation, userAccess]);

  useEffect(() => {
    if (!api) return;
    if (userAccess.role === "administrator") return;
    if (
      userAccess.privileges.some((singlePrivilege) => {
        return singlePrivilege === "operations.read";
      })
    )
      getUserOperations();
  }, [api, userAccess]);

  /* Handle Import Repeat Operation */
  const operationFormRequest = useSelector(
    (state: RootStateOrAny) => state.operations.operationFormRequest
  );

  useEffect(() => {
    if (!operationFormRequest) {
      setSelectedWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedAreaWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedCircleWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedCircleProperties({
        isCircle: true,
        center: [0.0, 0.0],
        radiusInKm: 0,
      });
      return;
    }
    const { request } = operationFormRequest;
    // Circle
    if (request.properties?.isCircle) {
      setSelectedCircleProperties(request.properties);
      setSelectedCircleWaypoints(request.area_coordinates);
      mapboxController.flyTo(request.properties.center);
    }
    // Polygon
    if (request.properties && !request.properties.isCircle) {
      setSelectedAreaWaypoints(request.area_coordinates);
      mapboxController.flyTo(request.area_coordinates[0]);
    }
    // Route
    if (!request.properties) {
      setSelectedWaypoints(request.waypoints);
      if (request.waypoints.length)
        mapboxController.flyTo(request.waypoints[0]);
    }
  }, [operationFormRequest]);

  const handleOperationFormWaypointChange = (
    waypoint: Waypoint[],
    type: CreateOperationType | "ResetForm"
  ) => {
    if (type === CreateOperationType.ROUTE) {
      setSelectedWaypoints(
        waypoint.map((singleWaypointArr) => {
          return [singleWaypointArr.longitude, singleWaypointArr.latitude];
        })
      );
    }
    if (type === CreateOperationType.POLYGON) {
      setSelectedAreaWaypoints(
        waypoint.map((singleWaypointArr) => {
          return [singleWaypointArr.longitude, singleWaypointArr.latitude];
        })
      );
    }
    if (type === CreateOperationType.CIRCLE) {
      const circleAreaCoordinates = circle(
        [waypoint[0].longitude, waypoint[0].latitude],
        waypoint[0].radius || 1
      );
      setSelectedCircleWaypoints(circleAreaCoordinates.geometry.coordinates[0]);
      setSelectedCircleProperties({
        isCircle: true,
        center: [waypoint[0].longitude, waypoint[0].latitude],
        radiusInKm: waypoint[0].radius || 1,
      });
    }
    if (type === "ResetForm") {
      setSelectedWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedAreaWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedCircleWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedCircleProperties({
        isCircle: true,
        center: [0.0, 0.0],
        radiusInKm: 0,
      });
    }
  };

  /* Handle Conformance Message */

  // Updated State Conformance data By Operation Id
  const retriveData = (conformance: any) => {
    const operation_id = conformance?.operation_id;
    const timeStart = conformance?.volume?.time_start?.value;
    const timeEnd = conformance?.volume?.time_end?.value;
    const dataConformance = conformance;
    const conformanceDataArr = [
      operation_id,
      timeStart,
      timeEnd,
      dataConformance,
    ];
    return conformanceDataArr;
  };

  const readingDataFromLocalStorage = (dataEntered: any) => {
    const listConformanceData = localStorage.getItem("conformanceData");
    const dataObject = {
      operation_id: dataEntered[0],
      timeStart: dataEntered[1],
      timeEnd: dataEntered[2],
      dataConformance: dataEntered[3],
    };
    const listConformanceDataArr = JSON.parse(
      listConformanceData || JSON.stringify([])
    );
    listConformanceDataArr?.push(dataObject);
    localStorage.setItem(
      "conformanceData",
      JSON.stringify(listConformanceDataArr)
    );
    const dataConformanceGL = localStorage.getItem("conformanceData");
    return JSON.parse(dataConformanceGL || JSON.stringify([]));
  };
  const handleConformanceWSMessage = (conformance: any) => {
    // const operationId = conformance?.operation_id
    //   ? conformance?.operation_id
    //   : undefined;
    if (conformance[0] !== "") {
      const dataEnteredLs = retriveData(conformance);
      const readDataLs = readingDataFromLocalStorage(dataEnteredLs);
      // remove(readDataLs, "operation_id", operationId);
      dispatch(
        setStateConformance({
          showConformanceLayer: true,
          data: readDataLs,
        })
      );
      // const convertCordinate =
      //   conformance?.volume?.volume?.outline_polygon?.vertices[0];
      // const coordinate = {
      //   reference: {
      //     id: "",
      //   },
      //   request: {
      //     arrival: getConvert([convertCordinate])[0],
      //   },
      // };
      // if (conformance.state === "Activated") return;
      // mapboxController.setFocussedOperation(coordinate);
    }
  };
  // const deletedOperationIdByTimeEnd = () => {
  //   const getDataConformanceLS = () => {
  //     const listConformanceData = localStorage.getItem("conformanceData")
  //       ? localStorage.getItem("conformanceData")
  //       : JSON.stringify([]);
  //     return JSON.parse(listConformanceData);
  //   };
  //   const dateNow = new Date();
  //   const customFilterIndex = () => {
  //     // const result = [];
  //     getDataConformanceLS().forEach((element, index) => {
  //       const time = new Date(element.timeEnd);
  //       const operationId = element.operation_id;
  //       if (time < dateNow) {
  //         removeByTime(getDataConformanceLS(), "operation_id", operationId);
  //         // return;
  //         // result.push(index);
  //       }
  //     });
  //     // return result;
  //   };
  //   customFilterIndex();
  // };
  // // Updated State Conformance data By Operation Id
  // const remove = (array, key, value) => {
  //   let arr1 = [];
  //   let arr2 = [];
  //   let removeData = [];
  //   if (value !== undefined) {
  //     const index = array.findIndex((obj) => obj[key] === value);
  //     arr1 = index >= 0 ? [...array.slice(0, index)] : array;
  //     arr2 = index >= 0 ? [...array.slice(index + 1)] : array;
  //     removeData = arr1.concat(arr2);
  //     // Chek if find index same with count of new data entered in LS retriveData(); if same then add new operationid data
  //     if (index === removeData.length) {
  //       localStorage.setItem("conformanceData", JSON.stringify(array));
  //     } else {
  //       localStorage.setItem("conformanceData", JSON.stringify(removeData));
  //     }
  //   }
  //   return removeData;
  // };
  // // Deleted Conformance data if Time End
  // const removeByTime = (array, key, value) => {
  //   let arr1 = [];
  //   let arr2 = [];
  //   let removeData = [];
  //   if (value !== undefined) {
  //     const index = array.findIndex((obj) => obj[key] === value);
  //     arr1 = index >= 0 ? [...array.slice(0, index)] : array;
  //     arr2 = index >= 0 ? [...array.slice(index + 1)] : array;
  //     removeData = arr1.concat(arr2);
  //     localStorage.setItem("conformanceData", JSON.stringify(removeData));
  //   }
  //   return removeData;
  // };

  /* Handle Authority Alert */
  useEffect(() => {
    const handleConformanceMessageAlert = (data: any) => {
      handleConformanceWSMessage(data);
      // remove(readDataLs, "operation_id", operationId);
      handleMessageAlert(data);
    };
    const handleMessageAlert = (data: any) => {
      if (!data) return;
      let { message } = data;
      let messageSecondLine: string;
      // console.log("Auth Message : ", data);
      if ("state" in data) {
        if (data.state === FLIGHT_STATES.ACTIVATED) {
          message = `The non-conforming operation has returned to its conformance
                    volume and has therefore returned to an
                    ${data.state} state.`;
        }
        if (data.state === FLIGHT_STATES.NON_CONFORMANT) {
          message = `An operation close by is ${data.state}.
                    Please fly with caution.`;
        }
        if (data.state === FLIGHT_STATES.CONTINGENT) {
          message = `An operation close by has deviated outside its conformance volume
                    beyond the allowed tolerance and is now ${data.state}.
                    Please fly with caution.`;
        } else {
          message = `Operation ${data?.operation_id || ""} has entered
                ${data.state} state.`;
        }
      } else if (data.message?.includes("+")) {
        const splitMessage = data.message.split("+");
        [message, messageSecondLine] = splitMessage;
      }

      toast.custom(
        (t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <IoIosAlert style={{ fontSize: "38px", color: "#B8860B" }} />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Alert</p>
                  <p className="mt-1 text-sm text-gray-500">{message}</p>
                  {messageSecondLine && (
                    <p className="mt-1 text-sm text-gray-500">
                      {messageSecondLine}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Acknowledge
              </button>
            </div>
          </div>
        ),
        {
          duration: Infinity,
        }
      );
    };
    const removeListenerAuthAlertMsg = channel?.addMessageListener(
      messageTypes.AUTHORITY_ALERT,
      handleMessageAlert
    );
    const removeListenerAlertMsg = channel?.addMessageListener(
      messageTypes.ALERT,
      handleMessageAlert
    );
    const removeListenerConformanceMsg = channel?.addMessageListener(
      messageTypes.CONFORMANCE,
      handleConformanceMessageAlert
    );

    return () => {
      removeListenerAuthAlertMsg?.();
      removeListenerAlertMsg?.();
      removeListenerConformanceMsg?.();
    };
  }, [channel, userAccess]);

  /* Handle Websocket Disconnect */

  const reconnectWsConnection = () => {
    const latestIdToken = localStorage.getItem("idToken");
    const role = "pilot";
    if (latestIdToken != null) {
      reconnectWs(latestIdToken, role);
    }
  };
  const checkWsConnection = () => {
    const channelStatus = channel?.checkConnection();
    if (channelStatus > 1) {
      reconnectWsConnection();
    }
  };

  const handleDisconnectMessage = (data: any) => {
    if (data.connection.connectionId === channel.connection.connectionId) {
      reconnectWsConnection();
    }
  };

  useEffect(() => {
    // check for disconnected status (internet outage then reconnect)
    const wsConnectionStatus = setInterval(async () => {
      checkWsConnection();
    }, 1000);
    // check for disconnected from notification service
    const removeHeartbeatListener = channel?.addMessageListener(
      messageTypes.HEARTBEAT,
      handleDisconnectMessage
    );
    return () => {
      clearInterval(wsConnectionStatus);
      removeHeartbeatListener?.();
    };
  }, [channel, userAccess]);

  const handleProposeChanges = () => {
    setSelectedMenu("Flight Authorization Propose Changes");
  };

  /* Handle Constraint Waypoint */

  const toRadians = (degrees: number) => degrees * (Math.PI / 180);

  const calculateDistance = (point1: number[], point2: number[]) => {
    const R = 6371; // Earth's radius in km
    const dLat = toRadians(point2[1] - point1[1]);
    const dLon = toRadians(point2[0] - point1[0]);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(point1[1])) *
        Math.cos(toRadians(point2[1])) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const calculateCenterAndRadius = (points: number[][]) => {
    const numPoints = points.length;
    const centroid = points.reduce(
      (acc, point) => {
        acc[1] += point[1] / numPoints;
        acc[0] += point[0] / numPoints;
        return acc;
      },
      [0, 0] as [number, number]
    );

    let maxDistance = 0;
    points.forEach((point) => {
      const distance = calculateDistance(centroid, point);
      if (distance > maxDistance) {
        maxDistance = distance;
      }
    });

    return { center: centroid, radius: maxDistance };
  };

  const handleConstraintWaypoints = (constraintData: Constraint) => {
    const countCoordinate =
      constraintData.extents[0].volume.outline_polygon.coordinates[0].length;
    /**
     * Parses constraints
     * @param {*} countCoordinate Count to make a diffrent between circle and polygon
     * @returns Constraints Circle Category
     */
    if (countCoordinate > 60) {
      const { center, radius } = calculateCenterAndRadius(
        constraintData.extents[0].volume.outline_polygon.coordinates[0]
      );
      const circleProps = {
        isCircle: true,
        center,
        radiusInKm: radius,
      };
      setSelectedAreaWaypoints(
        constraintData.extents[0].volume.outline_polygon.coordinates[0]
      );
      setSelectedCircleProperties(circleProps);
      return;
    }
    setSelectedCircleProperties({
      isCircle: false,
      center: [0.0, 0.0],
      radiusInKm: 0,
    });
    setSelectedAreaWaypoints(
      constraintData.extents[0].volume.outline_polygon.coordinates[0]
    );
  };

  // DJI Integration

  const isDJIDevice = getUA.includes("dji-open-platform");

  const getDjiUserDetails = async () => {
    const user = await Auth.currentAuthenticatedUser();
    // djiPilot2Integration(showToast, user.username);
    setDjiUsername(user.username);
  };

  useEffect(() => {
    if (!api) return;
    if (isDJIDevice) {
      showToast("DJI Device Detected, Configuring Device", true);
      // getDjiUserDetails();
      if (djiUserUuid && djiUsername) {
        djiPilot2Integration(api, showToast, djiUserUuid, djiUsername);
      } else if (!djiUsername) {
        getDjiUserDetails();
      }
    }
  }, [api, djiUserUuid, djiUsername]);

  // function loadDJIMissionAPI(userName: string) {
  //   const getPilot = async () => {
  //     try {
  //       const responses = await api.getPilots();
  //       const userUuid = responses.data.map((response: any) => {
  //         if (userName === response.pilot_username) {
  //           return response.pilot_uuid;
  //         }
  //       });

  //       if (!userUuid) {
  //         showToast("Unable to obtain user information", false, true);
  //       } else {
  //         DJIMissionAPI(api, dispatch, userUuid, userName);
  //       }
  //     } catch (err: any) {
  //       showToast("Unable to obtain user information", false, true);
  //     }
  //   };

  //   getPilot();
  // }
  // KIV - implement smaller resource display for DJI Device
  // if (isDJIDevice) return <div></div>;

  // DJI Integration END

  /* Schdule Management Draw Coordinate */

  const handleScheduleManagementDrawOutline = (
    isClearDrawings: boolean,
    schedule?: Schedule
  ) => {
    if (isClearDrawings) {
      setSelectedAreaWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      return;
    }
    if (!schedule) return;
    const { request } = schedule;
    // Polygon
    if (request.area_coordinates) {
      setSelectedAreaWaypoints(request.area_coordinates);
      mapboxController.flyTo(request.area_coordinates[0]);
    }
    // Route
    if (request.waypoints) {
      setSelectedWaypoints(request.waypoints);
      if (request.waypoints.length)
        mapboxController.flyTo(request.waypoints[0]);
    }
  };

  return (
    <div className="relative">
      <div className="w-screen h-screen overflow-hidden flex-col">
        {/* Header */}
        <DashboardHeader
          api={api}
          isAuthority={isAuthority}
          onPlaceSelected={goToLocation}
          showMessage={showToast}
          onConfirmation={handleOnConfirmation}
          onThemeChange={(newTheme) => setCurrentTheme(newTheme)}
          onMenuSelected={handleSideBarSelection}
          onOpenModal={handleGeneralModal}
          isDJIDevice={isDJIDevice}
        />

        <div className="w-full h-full flex bg-gray-500">
          {/* SideBar */}
          <DashboardSideBar
            isSidebarHidden={hideSideBar}
            onMenuSelected={handleSideBarSelection}
            sidebarWidth={sideBarWidth}
            currentTheme={currentTheme}
          >
            <div
              className={`side-panel relative overflow-auto ${sideBarWidth}`}
            >
              {/* Operator Menu */}
              {selectedMenu === "Flight Authorization" &&
                !isAirspaceManager && (
                  <FlightAuthorizationSidebar
                    api={api}
                    /* Route Operation */
                    selectedWaypoint={selectedWaypoints}
                    /* Polygon Operation */
                    selectedAreaWaypoints={selectedAreaWaypoints}
                    /* Circle Operation */
                    selectedCircleWaypoints={selectedCircleWaypoints}
                    setSelectedCircleWaypoints={setSelectedCircleWaypoints}
                    selectedCircleProperties={selectedCircleProperties}
                    setSelectedCircleProperties={setSelectedCircleProperties}
                    setIsFormOpen={handleOnOperationForm}
                    onWaypointUpdated={handleSetSelectedWaypoints}
                    onConfirmation={handleOnConfirmation}
                    showMessage={showToast}
                    goToLocation={goToLocation}
                    onOpenModal={handleGeneralModal}
                    onCloseModal={() => setIsGeneralModalOpen(false)}
                    onCloseSidebar={() => setHideSideBar(true)}
                    handleSetEmergencyLanding={handleSetEmergencyLanding}
                    emergencyLanding={emergencyLanding}
                    handleOperationFormWaypointChange={
                      handleOperationFormWaypointChange
                    }
                    handleEditOperation={handleEditOperation}
                  />
                )}

              {/* Operator Menu */}
              {selectedMenu === "Schedule Management" && (
                <FlightAuthorizationSidebar
                  api={api}
                  /* Route Operation */
                  selectedWaypoint={selectedWaypoints}
                  /* Polygon Operation */
                  selectedAreaWaypoints={selectedAreaWaypoints}
                  /* Circle Operation */
                  selectedCircleWaypoints={selectedCircleWaypoints}
                  setSelectedCircleWaypoints={setSelectedCircleWaypoints}
                  selectedCircleProperties={selectedCircleProperties}
                  setSelectedCircleProperties={setSelectedCircleProperties}
                  setIsFormOpen={handleOnOperationForm}
                  onWaypointUpdated={handleSetSelectedWaypoints}
                  onConfirmation={handleOnConfirmation}
                  showMessage={showToast}
                  goToLocation={goToLocation}
                  onOpenModal={handleGeneralModal}
                  onCloseModal={() => setIsGeneralModalOpen(false)}
                  onCloseSidebar={() => setHideSideBar(true)}
                  handleSetEmergencyLanding={handleSetEmergencyLanding}
                  emergencyLanding={emergencyLanding}
                  handleOperationFormWaypointChange={
                    handleOperationFormWaypointChange
                  }
                  handleEditOperation={handleEditOperation}
                  isScheduleManagement={true}
                  handleSetSelectedWaypoints={handleSetSelectedWaypoints}
                  handleScheduleManagementDrawOutline={
                    handleScheduleManagementDrawOutline
                  }
                />
              )}

              {selectedMenu === "Weather Analysis" && (
                <WeatherAnalysisSidebar
                  api={api}
                  setIsFormOpen={handleOnOperationForm}
                  onConfirmation={handleOnConfirmation}
                  showMessage={showToast}
                  onOpenModal={handleGeneralModal}
                  onCloseModal={() => setIsGeneralModalOpen(false)}
                  onCloseSidebar={() => setHideSideBar(true)}
                />
              )}

              {selectedMenu === "Drone Ports Operations" && (
                <DronePortsOperationSidebar
                  api={api}
                  onConfirmation={handleOnConfirmation}
                  showMessage={showToast}
                  goToLocation={goToLocation}
                  setSelectedWaypoints={setSelectedWaypoints}
                />
              )}
              {selectedMenu === "Assets Management" && (
                <AssetManagementSidebar
                  api={api}
                  onConfirmation={handleOnConfirmation}
                  showMessage={showToast}
                  onOpenModal={handleGeneralModal}
                  goToLocation={goToLocation}
                />
              )}

              {selectedMenu === "Flight Logs" && (
                <FlightLogs
                  api={api}
                  showMessage={showToast}
                  onOpenModal={handleGeneralModal}
                  onCloseModal={() => setIsGeneralModalOpen(false)}
                  handleRepeatOperation={handleRepeatOperation}
                />
              )}

              {selectedMenu === "Incident Reports" && (
                <ReportsSidebar
                  showMessage={showToast}
                  onOpenModal={handleGeneralModal}
                  onCloseModal={() => setIsGeneralModalOpen(false)}
                />
              )}
              {selectedMenu === "DJI Integration" && (
                <ImportOperationSidebar
                  api={api}
                  showMessage={showToast}
                  onOpenFlightAuth={() =>
                    setSelectedMenu("Flight Authorization")
                  }
                />
              )}
              {/* End of Operator Menu */}

              {/* Tracker Menu */}
              {selectedMenu === "Tracker Management" && (
                <TrackerManagement
                  api={api}
                  showMessage={showToast}
                  selectedFlightData={selectedFlightData}
                  operationSummary={operationSummary}
                />
              )}

              {/* Administrator Menu */}
              {selectedMenu === "User Management" && (
                <UserManagement
                  api={api}
                  showMessage={showToast}
                  onOpenModal={handleGeneralModal}
                  onConfirmation={handleOnConfirmation}
                  onCloseModal={() => setIsGeneralModalOpen(false)}
                />
              )}

              {/* Authority Menu */}
              {selectedMenu === "Constraints Management" && (
                <ConstraintManagementSidebar
                  api={api}
                  constraintList={constraints}
                  /* Polygon Drawing */
                  selectedAreaWaypoints={selectedAreaWaypoints}
                  /* Circle Drawing */
                  selectedCircleWaypoints={selectedCircleWaypoints}
                  setSelectedCircleWaypoints={setSelectedCircleWaypoints}
                  selectedCircleProperties={selectedCircleProperties}
                  setSelectedCircleProperties={setSelectedCircleProperties}
                  /*  */
                  setIsFormOpen={handleOnOperationForm}
                  onWaypointUpdated={handleSetSelectedWaypoints}
                  onConfirmation={handleOnConfirmation}
                  showMessage={showToast}
                  goToLocation={goToLocation}
                  onOpenModal={handleGeneralModal}
                  onCloseModal={() => setIsGeneralModalOpen(false)}
                  handleConstraintWaypoints={handleConstraintWaypoints}
                  handleOperationFormWaypointChange={
                    handleOperationFormWaypointChange
                  }
                />
              )}

              {selectedMenu === "Operator Management" && (
                <OperatorManagement
                  api={api}
                  showMessage={showToast}
                  onOpenModal={handleGeneralModal}
                  onCloseModal={() => setIsGeneralModalOpen(false)}
                />
              )}
              {selectedMenu === "Flight Authorization" && isAirspaceManager && (
                <FlightAuthorizationAuthority
                  api={api}
                  /* Route Operation */
                  onConfirmation={handleOnConfirmation}
                  showMessage={showToast}
                  onOpenModal={handleGeneralModal}
                  onCloseModal={() => setIsGeneralModalOpen(false)}
                  handleProposeChanges={handleProposeChanges}
                />
              )}
              {selectedMenu === "Flight Authorization Propose Changes" &&
                isAirspaceManager && (
                  <FlightAuthorizationSidebar
                    api={api}
                    /* Route Operation */
                    selectedWaypoint={selectedWaypoints}
                    /* Polygon Operation */
                    selectedAreaWaypoints={selectedAreaWaypoints}
                    /* Circle Operation */
                    selectedCircleWaypoints={selectedCircleWaypoints}
                    setSelectedCircleWaypoints={setSelectedCircleWaypoints}
                    selectedCircleProperties={selectedCircleProperties}
                    setSelectedCircleProperties={setSelectedCircleProperties}
                    setIsFormOpen={handleOnOperationForm}
                    onWaypointUpdated={handleSetSelectedWaypoints}
                    onConfirmation={handleOnConfirmation}
                    showMessage={showToast}
                    goToLocation={goToLocation}
                    onOpenModal={handleGeneralModal}
                    onCloseModal={() =>
                      handleSideBarSelection("Flight Authorization", false)
                    }
                    onCloseSidebar={() => setHideSideBar(true)}
                    handleSetEmergencyLanding={handleSetEmergencyLanding}
                    emergencyLanding={emergencyLanding}
                    handleOperationFormWaypointChange={
                      handleOperationFormWaypointChange
                    }
                    handleEditOperation={handleEditOperation}
                    isAuthority={isAirspaceManager}
                  />
                )}

              {/* End of Authority Menu */}
            </div>
          </DashboardSideBar>

          <div className="relative w-full h-full">
            {/* ToolBar */}
            <MapToolbar
              onToggleView={handleToggleView}
              onToggleOverlay={handleToggleOverlay}
              showMessage={showToast}
            />

            {/* Map */}
            <MapView
              mapTheme={currentTheme}
              constraints={constraints}
              showShipLayer={showShipLayer}
              showAdsbLayer={showAdsbLayer}
              showConstraintsLayer={showConstraintsLayer}
              showStaticConstraintsLayer={showStaticConstraintsLayer}
              showRainMapLayer={showRainMapLayer}
              showAirMapSpace={showAirMapSpace}
              showOperationVolumeLayer={showOperationVolumeLayer}
              showMapElevation={showMapElevation}
              showDroneIconLayer={showDroneIconLayer}
              showGnssLayer={showGnssLayer}
              showAnchorageLayer={showAnchorageLayer}
              showDronePortLayer={showDronePortLayer}
              showSgBoundaryLayer={showSgBoundaryLayer}
              showSgPopulationDensity={showSgPopulationDensity}
              showCollisionLayer={showCollisionLayer}
              selectedFlightData={selectedFlightData}
              selectedTrackerData={selectedTrackerData}
              selectedPlatformData={selectedPlatformData}
              emergencyLanding={emergencyLanding}
              selectedWaypoints={selectedWaypoints}
              selectedAreaWaypoints={selectedAreaWaypoints}
              selectedCircleWaypoints={selectedCircleWaypoints}
              selectedCircleProperties={selectedCircleProperties}
              handleSetEmergencyLanding={handleSetEmergencyLanding}
              handleSetSelectedWaypoints={handleSetSelectedWaypoints}
              handleSetSelectedAreaWaypoints={handleSetSelectedAreaWaypoints}
              handleSetSelectedCircleProperties={
                handleSetSelectedCircleProperties
              }
              handleSetSelectedCircleWaypoints={
                handleSetSelectedCircleWaypoints
              }
              handleToggleMostRecentComponent={() =>
                handleTemporary("handleToggleMostRecentComponent", null)
              }
              handleOpenShipDeliveryOperationsForced={() =>
                handleTemporary("shipDelivery", null)
              }
              setSelectedShip={handleTemporary}
            />

            {/* Panel */}
            {showOperationPanel && userAccess.role !== "administrator" && (
              <div className="absolute bottom-20 right-1 p-4">
                <OperationPanel
                  api={api}
                  isAuthority={isAirspaceManager}
                  groundSummary={groundSummary}
                  airborneSummary={airborneSummary}
                  operationsSummary={operationSummary}
                  selectedFlightData={selectedFlightData}
                  flightDataIsLoading={flightDataIsLoading}
                  selectedPlatformData={selectedPlatformData}
                  platformList={platformList.current}
                  trackerList={trackerList.current}
                  pilotList={pilotList.current}
                  showMessage={showToast}
                  onOpenModal={handleGeneralModal}
                  onCloseModal={() => setIsGeneralModalOpen(false)}
                />
              </div>
            )}
          </div>
        </div>
        <Toaster />
      </div>

      {/* Confirmation Modal */}
      {isConfirmationModalOpen && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[999]">
          <div className="rounded-lg bg-white p-4 w-96 flex-col items-center justify-center">
            <h4>{dialogDetail.title}</h4>
            <p className="mb-6">{dialogDetail.message}</p>
            <div className="flex justify-end mt-4 w-full">
              <Button
                type="secondary"
                size="small"
                text="Confirm"
                className="mr-2 w-fit"
                onClick={() => handleConfirmationModalClose(true)}
              />
              <Button
                type="primary"
                size="small"
                text="Cancel"
                className="w-fit"
                onClick={() => handleConfirmationModalClose(false)}
              />
            </div>
          </div>
        </div>
      )}

      {/* General Modal */}
      {isGeneralModalOpen && (
        <div
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[999]"
          onClick={() => {
            if (!generalModalDetail.disableDismiss) {
              setIsGeneralModalOpen(false);
            }
          }}
        >
          <div className="modal-container flex-col">
            {generalModalDetail.showTitle && (
              <div
                className="flex mb-1 content-center items-center pl-2"
                onClick={(e) => e.stopPropagation()}
              >
                <h4 className="grow">{generalModalDetail.title}</h4>
                <button
                  className="p-2"
                  onClick={() => setIsGeneralModalOpen(false)}
                >
                  <IoIosClose size={32} />
                </button>
              </div>
            )}
            {generalModalDetail.content}
          </div>
        </div>
      )}
    </div>
  );
}

function useNotifications() {
  const [notifications, setNotifications] = useState<any>([]);
  const [haveNewNotifications, setHaveNewNotifications] = useState(false);
  const [olderNotifications, setOlderNotifications] = useState<any>([]);
  const [olderNotificationsCount, setOlderNotificationsCount] = useState(false); // set to true to retrieve older notifications
  const api = useApi();

  useEffect(() => {
    if (notifications.length > 0 && olderNotificationsCount) {
      const olderNotificationList = olderNotifications;
      if (olderNotificationList.length > 0) {
        const data = {
          lastEvaluatedKey: {
            PK: olderNotificationList[olderNotificationList.length - 1].PK,
            SK: olderNotificationList[olderNotificationList.length - 1].SK,
          },
        };
        getOlderNotifications(data);
      } else {
        const data = {
          lastEvaluatedKey: {
            PK: notifications[notifications.length - 1].PK,
            SK: notifications[notifications.length - 1].SK,
          },
        };
        getOlderNotifications(data);
      }
    }
  }, [olderNotificationsCount]);

  const getOlderNotifications = async (data: any) => {
    const mergedResponse = [];
    const res = await api?.getLogMessages(data);

    const responseArray = res.data?.items;
    mergedResponse.push(...olderNotifications);
    mergedResponse.push(...responseArray);
    setOlderNotifications(mergedResponse);
    setOlderNotificationsCount(false);
  };

  const handleGetOlderNotifications = () => {
    setOlderNotificationsCount(true);
  };

  return {
    notifications,
    haveNewNotifications,
    handleGetOlderNotifications,
    olderNotifications,
    setNotifications,
    setHaveNewNotifications,
  };
}
