import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import axios from "axios";
import { IoIosArrowBack, IoIosMore } from "react-icons/io";
import UserAccess from "@/model/UserAccess";
import { Operation } from "@/model/api/Operation";
import { Schedule } from "@/model/api/Schedule";
import moment from "moment";

import ItemOperationSchedule from "./ItemCards/ItemOperationSchedule";
import ItemSimulation from "./ItemCards/ItemSimulation";
import SimulationForm from "./SimulationForm";

import Button from "../../Common/Button";
import Loader from "../../Common/Loader";

interface UserAccessState {
  userAccess: UserAccess;
}

interface GenerateSimulationForm {
  flight_simulation: boolean;
  mission_simulation: boolean;
}
interface SimulationListType {
  simulation_uuid: string;
  flight_simulation_uuid?: string;
  flight_simulation_eval?: string;
  mission_simulation_uuid?: string;
  date_created: string;
}

interface FlightAuthorizationSidebarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;
  /*  */
  setIsFormOpen: (value: boolean) => void;
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
  onCloseSidebar: () => void;
}

interface submitGenerateSimulationFormProps {
  submitGenerateSimulation: (
    flight_simulation: boolean,
    mission_simulation: boolean
  ) => void;
  isLoading: boolean;
  onCloseModal: () => void;
}

function SubmitGenerateSimulationForm({
  submitGenerateSimulation,
  isLoading,
  onCloseModal,
}: submitGenerateSimulationFormProps) {
  const [form, setForm] = useState<GenerateSimulationForm>({
    flight_simulation: true,
    mission_simulation: true,
  });

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setForm({
      ...form,
      [name]: e.target.checked,
    });
  };

  const handleSubmitClick = () => {
    submitGenerateSimulation(form.flight_simulation, form.mission_simulation);
  };

  return (
    <div
      className="flex flex-col p-4 overflow-auto"
      onClick={(e) => e.stopPropagation()}
    >
      {/* Loader */}
      {isLoading && <Loader isLoading={isLoading} isError={false} />}
      <div className="flex flex-col">
        <span className="text-primary-600 text-sm font-medium mb-5">
          Simulation Types:
        </span>
        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            className="input-checkbox"
            name="flight_simulation"
            defaultChecked={form.flight_simulation}
            onChange={handleCheckboxChange}
          />
          <label className="ml-2 text-sm">Flight Simulation</label>
        </div>
        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            className="input-checkbox"
            name="mission_simulation"
            defaultChecked={form.mission_simulation}
            onChange={handleCheckboxChange}
          />
          <label className="ml-2 text-sm">Mission Simulation</label>
        </div>
      </div>
      <div className="flex mt-10">
        <Button
          type="primaryDark"
          size="small"
          fixedWidth={true}
          className="w-32"
          text="Submit"
          onClick={() => handleSubmitClick()}
        />
        <span className="grow" />
        <Button
          type="light"
          size="small"
          fixedWidth={true}
          className="w-32 mr-2"
          text="Cancel"
          onClick={() => onCloseModal()}
        />
      </div>
    </div>
  );
}
export default function WeatherAnalysisSidebar({
  api,
  setIsFormOpen,
  onConfirmation,
  showMessage,
  onOpenModal,
  onCloseModal,
  onCloseSidebar,
}: FlightAuthorizationSidebarProps) {
  /* More Options */
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  /* API State */
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDisplaying, setIsDisplaying] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* Upcoming Operations */
  const [upcomingOperations, setUpcomingOperations] = useState<Operation[]>([]);

  /* Upcoming Schedules */
  const [upcomingSchedules, setUpcomingSchedules] = useState<Schedule[]>([]);

  /* Simulations List */
  const [simulationsList, setSimulationsList] = useState<SimulationListType[]>(
    []
  );
  const [simulationsListHeader, setSimulationsListHeader] = useState("");

  /* Generate Simulation */
  const [generateSimulationUuid, setGenerateSimulationUuid] = useState("");

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [lastRefreshTime, setLastRefreshTime] = useState<string>("");

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  useEffect(() => {
    // XOR for when user only has 1 privilege
    if (
      userAccess.privileges.includes("operations.read") !==
      userAccess.privileges.includes("schedules.read")
    ) {
      setSelectedTab(userAccess.privileges.includes("operations.read") ? 0 : 1);
    }
  }, [userAccess]);
  const handleSelectedTab = (option: number) => {
    setSelectedTab(option);
    setSelectedIds([]);
    setShowMoreOptions(false);
  };

  const handleSimulationSelected = (simulationId: string) => {
    const index = selectedIds.indexOf(simulationId);
    if (index === -1) {
      setSelectedIds([...selectedIds, simulationId]);
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== simulationId));
    }
    setShowMoreOptions(false);
  };

  const handleSimulationClicked = async (
    simulationUuid: string,
    isFlightSimulation: boolean
  ) => {
    showMessage && showMessage("Obtaining simulation data, please wait");
    setIsLoading(true);
    try {
      const simulationDetailsRes = await api.getSimulationDetails(
        simulationUuid
      );

      if (
        (isFlightSimulation &&
          simulationDetailsRes?.data?.flight_simulation_data?.message) ||
        (!isFlightSimulation &&
          simulationDetailsRes?.data?.mission_simulation_data?.message)
      ) {
        showMessage &&
          showMessage(
            isFlightSimulation
              ? simulationDetailsRes?.data?.flight_simulation_data?.message
              : simulationDetailsRes?.data?.mission_simulation_data?.message
          );
        setIsLoading(false);
        return;
      }

      const content = (
        <SimulationForm
          simulationData={simulationDetailsRes.data}
          handleClose={() => onCloseModal()}
          isFlightSimulation={isFlightSimulation}
        />
      );

      onOpenModal(
        `Weather Analysis Details: ${
          isFlightSimulation ? "Flight" : "Mission"
        }`,
        content
      );
    } catch (err) {
      let message = "Error obtaining simulations";
      if (axios.isAxiosError(err)) {
        message = err?.response?.data?.message;
      }
      setIsLoading(false);
      showMessage && showMessage(message, false, true);
    }
    setIsLoading(false);
  };

  const fetchOperations = async () => {
    setIsError(false);
    setErrorMessage("");
    setIsLoading(true);
    try {
      if (userAccess.privileges.includes("operations.read")) {
        const upcomingOperations = await api.getOperations(
          false,
          0,
          true,
          false
        );
        if (upcomingOperations.error) {
          setIsError(true);
          setErrorMessage("Unable to Retrive Operations Data");
        } else {
          const UpcomingOperationsSorted = upcomingOperations.data.sort(
            (a: Operation, b: Operation) => {
              const timeStartA = new Date(
                a.operation_json.interuss.operational_intent_reference.time_start.value
              );
              const timeStartB = new Date(
                b.operation_json.interuss.operational_intent_reference.time_start.value
              );
              return timeStartB.getTime() - timeStartA.getTime();
            }
          );
          setUpcomingOperations(UpcomingOperationsSorted);
        }
      }
      if (userAccess.privileges.includes("schedules.read")) {
        const timeStamp = new Date().getTime();
        const upcomingSchedules = await api.getSchedules(
          timeStamp,
          false,
          false,
          false
        );
        if (upcomingSchedules.error || upcomingSchedules.error) {
          setIsError(true);
          setErrorMessage("Unable to Retrive Operations Data");
        } else {
          const upcomingSchedulesSorted = upcomingSchedules.data.sort(
            (a: Schedule, b: Schedule) => {
              const timeStartA = new Date(a.request.time_start);
              const timeStartB = new Date(b.request.time_start);
              return timeStartB.getTime() - timeStartA.getTime();
            }
          );

          setUpcomingSchedules(upcomingSchedulesSorted);
        }
      }
      const currentTime = moment(new Date()).format("HH:mm A");

      setLastRefreshTime(currentTime);
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setErrorMessage("Unable to Retrive Operations Data");
    }
  };

  const copyOperationId = (operationId: string) => {
    navigator.clipboard.writeText(operationId);
    showMessage && showMessage("Operation ID Copied", true);
  };

  const handleReturnToList = () => {
    setIsAdd(false);
    setIsFormOpen(false);
    setSimulationsList([]);
    setSelectedIds([]);
    setSimulationsListHeader("");
    setGenerateSimulationUuid("");
  };

  useEffect(() => {
    if (api) {
      setIsLoading(true);
      fetchOperations();
    }
  }, [api]);

  const fetchSimulations = async (isOperation: boolean, uuid: string) => {
    setIsLoading(true);
    try {
      if (isOperation) {
        const operationSimulationRes = await api.getOperationSimulation(uuid);
        setSimulationsList(operationSimulationRes.data);
      } else {
        const schduleSimulationRes = await api.getScheduleSimulation(uuid);
        setSimulationsList(schduleSimulationRes.data);
      }
    } catch (err) {
      let message = "Error obtaining simulations";
      if (axios.isAxiosError(err)) {
        message = err?.response?.data?.message;
      }
      setIsLoading(false);
      showMessage && showMessage(message, false, true);
    }

    setIsLoading(false);
  };

  const handleOperationScheduleClick = async (
    isOperation: boolean,
    intent?: string,
    uuid?: string
  ) => {
    if (!uuid) return;
    showMessage && showMessage("Obtaining simulations, please wait");
    setIsAdd(true);
    setGenerateSimulationUuid(uuid);
    const headerMessage = isOperation
      ? `Operation : ${intent}`
      : `Schedule : ${intent}`;
    setSimulationsListHeader(headerMessage);
    setIsLoading(true);
    fetchSimulations(isOperation, uuid);
  };

  const submitGenerateSimulation = async (
    flight_simulation: boolean,
    mission_simulation: boolean
  ) => {
    const isOperation = selectedTab === 0;

    // validate form
    if (!flight_simulation && !mission_simulation) {
      showMessage &&
        showMessage("Select at least 1 simulation type", false, true);
      return;
    }
    showMessage("Submitting generate simulation request");
    onCloseModal();
    setIsLoading(true);
    try {
      if (isOperation) {
        await api.submitOperationSimulation(
          generateSimulationUuid,
          flight_simulation,
          mission_simulation
        );
      } else {
        await api.submitScheduleSimulation(
          generateSimulationUuid,
          flight_simulation,
          mission_simulation
        );
      }
      showMessage("Submitted request successfully", true);
      fetchSimulations(isOperation, generateSimulationUuid);
    } catch (err) {
      let message = "Error submiting simulation request";
      if (axios.isAxiosError(err)) {
        message = err.response?.data?.message || err.message;
      }
      showMessage && showMessage(message, false, true);
    }
    setIsLoading(false);
  };

  const handleGenerateSimulationClicked = () => {
    const content = (
      <SubmitGenerateSimulationForm
        submitGenerateSimulation={submitGenerateSimulation}
        isLoading={isLoading}
        onCloseModal={onCloseModal}
      />
    );
    onOpenModal(`Generate Simulation`, content);
  };

  const handleDeleteSimulation = () => {
    onConfirmation(
      "Delete Simulation",
      "Are you sure you want to delete the selected simulation? Action cannot be undone",
      (result) => {
        if (result) {
          const operationPromises = selectedIds.map((simulationUuid) =>
            api.deleteSimulation(simulationUuid)
          );

          setIsDeleting(true);
          showMessage("Deleting Simulation, Please Wait");

          Promise.all(operationPromises)
            .then(() => {
              showMessage && showMessage("Simulation Deleted", true);
              setSelectedIds([]);
              fetchOperations();
            })
            .catch(() => {
              showMessage("Unable to Delete Simulation", false, true);
            })
            .finally(() => setIsDeleting(false));
        }
      }
    );
    fetchSimulations(selectedTab === 0, generateSimulationUuid);
    setSelectedIds([]);
  };

  return (
    <div className="absolute top-0 bottom-0 w-full z-[9999]">
      {/* Data List */}
      {!isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          <div className="h-[90%] flex-col">
            {/* Header */}
            <div className="flex p-4 h-[8%] border-b border-b-gray-300 dark:border-b-gray-700">
              <h4> Weather Analysis</h4>
              <span className="grow" />
              <div className="w-6 h-6 bg-blue-200 rounded-lg flex dark:bg-darkSecondary">
                <IoIosMore
                  className="fill-blue-500 dark:fill-white m-auto"
                  size={16}
                />
              </div>
            </div>
            <div className="flex-col px-4">
              {/* Main Tab */}
              {userAccess.privileges.includes("operations.read") &&
                userAccess.privileges.includes("schedules.read") && (
                  <div className="tab-rounded mt-2">
                    <span
                      className={`${selectedTab === 0 && "tab-active"} mr-1`}
                      onClick={() => handleSelectedTab(0)}
                    >
                      Operations
                    </span>
                    <span
                      className={`${selectedTab === 1 && "tab-active"} ml-1`}
                      onClick={() => handleSelectedTab(1)}
                    >
                      Schedules
                    </span>
                  </div>
                )}
              <div className="flex px-4 py-2 mt-2">
                <h5 className="font-medium ml-2">
                  {selectedTab === 0 ? "Select Operation" : "Select Schedule"}
                </h5>
                <span className="grow" />
                <button onClick={() => fetchOperations()}>
                  <span className="text-primary-600 text-sm font-medium mr-2">
                    Last Refreshed : {lastRefreshTime}
                  </span>
                </button>
              </div>
            </div>
            {/* Content */}
            <div className="h-[85%] flex-col overflow-auto p-4">
              {selectedTab === 0
                ? upcomingOperations.map((singleOperation: Operation) => {
                    return (
                      <ItemOperationSchedule
                        key={singleOperation.operation_json.reference.id}
                        operation={singleOperation}
                        isOperation={true}
                        isDeleting={isDeleting}
                        isSelected={selectedIds.includes(
                          singleOperation.operation_json.reference.id
                        )}
                        copyOperationId={copyOperationId}
                        onClick={(uuid?: string) =>
                          handleOperationScheduleClick(
                            true,
                            singleOperation.operation_json.reference.intent,
                            uuid
                          )
                        }
                        onSelected={handleSimulationSelected}
                      />
                    );
                  })
                : upcomingSchedules.map((singleSchedule: Schedule) => {
                    return (
                      <ItemOperationSchedule
                        key={singleSchedule.schedule_uuid}
                        schedule={singleSchedule}
                        isOperation={false}
                        isDeleting={isDeleting}
                        isSelected={selectedIds.includes(
                          singleSchedule.schedule_uuid
                        )}
                        copyOperationId={copyOperationId}
                        onClick={(uuid?: string) =>
                          handleOperationScheduleClick(
                            false,
                            singleSchedule.request.intent,
                            uuid
                          )
                        }
                        onSelected={handleSimulationSelected}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
      )}

      {/* Forms */}

      {isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          {/* Navigation & Form Title */}
          <div className="flex py-2 items-center content-center">
            <button onClick={handleReturnToList} className="p-2">
              <IoIosArrowBack size={24} className="m-auto" />
            </button>
            <h5 className="font-medium ml-2"> {simulationsListHeader}</h5>
          </div>
          <hr />

          {!isLoading && simulationsList.length !== 0 && (
            <div className="h-[75%] flex-col overflow-auto p-4">
              {simulationsList.map((singleSimulation: SimulationListType) => (
                <ItemSimulation
                  key={singleSimulation.simulation_uuid}
                  simulationItem={singleSimulation}
                  isDeleting={isDeleting}
                  isSelected={selectedIds.includes(
                    singleSimulation.simulation_uuid
                  )}
                  copyOperationId={copyOperationId}
                  onClick={handleSimulationClicked}
                  onSelected={handleSimulationSelected}
                />
              ))}
            </div>
          )}

          {/* No Data Available */}
          {!isLoading && !simulationsList.length && (
            <div className="h-[75%] flex-col overflow-auto p-4">
              <p className="p-8 text-center">No Simulation Data</p>
            </div>
          )}

          {/* Loader */}
          <Loader
            isLoading={isLoading}
            isError={isError}
            errorText={errorMessage}
          />

          {/* Buttons */}
          {!isLoading && userAccess.privileges.includes("sdsp.write") && (
            <div className="h-[10%] px-4 py-2 items-center content-center">
              {selectedIds.length > 0 ? (
                <div className="flex">
                  <Button
                    type="danger"
                    size="medium"
                    text="Delete Simulation"
                    className="mr-2"
                    isLoading={isDisplaying}
                    disabled={isDeleting || isDisplaying}
                    onClick={() => handleDeleteSimulation()}
                  />
                </div>
              ) : (
                <div className="flex">
                  <Button
                    type="primaryDark"
                    size="medium"
                    text="Generate Simulation"
                    className="mr-2"
                    isLoading={isDisplaying}
                    disabled={isDeleting || isDisplaying}
                    onClick={() => handleGenerateSimulationClicked()}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* Button Options */}
      {/* {showMoreOptions && (
        <div
          className="w-40 h-18
           absolute bottom-40 right-6 popup-menu"
        >
          <ul className="flex-col">
            <li onClick={}>Export KML</li>
            <li onClick={}>Edit</li>
            <li onClick={}>Delete</li>
          </ul>
        </div>
      )} */}
    </div>
  );
}
